import React, { useEffect } from 'react';

import { SEO } from '../../components';
import { Background, Product } from '../../lib/types';
import { useAppDispatch } from '../../redux/hooks';
import { changeBackground, changeCurrentLogo } from '../../redux/reducers/ui';
import { Connect, PageLayout } from '../../widgets';

import '../../assets/fonts/jakarta.css';

const ConnectPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeBackground(Background.Connect));
    dispatch(changeCurrentLogo({ product: Product.Connect, isWhite: true }));
  }, [dispatch]);

  return (
    <>
      <SEO
        title="Arteria Connect"
        description="Legal alternative to Fintech as a Service in Mexico"
        image="../assets/images/logos/connect.svg"
      />
      <PageLayout isOverflowActive>
        <Connect />
      </PageLayout>
    </>
  );
};

export default ConnectPage;
